<template>
  <div class="information">
    <div class="information_title">个人信息</div>
    <div class="information_flex">
      <div class="information_left">
        <el-upload
          class="avatar-uploader"
          action=""
          :http-request="upload"
          :show-file-list="false"
        >
          <img v-if="form.headImgUrl" :src="form.headImgUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
      <div class="information_right">
        <el-form
          :model="form"
          label-width="90px"
          :inline="true"
          label-position="left"
          class="demo-form-inline"
        >
          <el-form-item label="用户名">
            <el-input v-model="form.nickname"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="角色：">
            <div v-if="form.grade === 'THE_ADMINISTRATOR'">管理员</div>
            <div v-if="form.grade === 'TECHNICAL_PERSONNEL'">技术人员</div>
            <div v-if="form.grade === 'OPERATING_PERSONNEL'">运营人员</div>
          </el-form-item>
          <el-form-item label="创建时间：">
            <div>{{ form.createTime }}</div>
          </el-form-item>
          <el-form-item>
            <el-button style="width: 120px" type="warning" @click="sumbitClick"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserId, editsSave } from "@/api/administrator";
import { uploadAdmin } from "@/api/common";
export default {
  name: "Information",
  data() {
    return {
      form: {
        nickname: "",
        phone: "",
        grade: "",
        createTime: "",
        headImgUrl: "",
      },
    };
  },
  computed: {
    userId() {
      return this.$route.params.userId;
    },
  },
  created() {
    this.userIdAxios();
  },
  methods: {
    //上传图片
    async upload(params) {
      const form = new FormData();

      form.append("file", params.file);

      form.append("imgName", params.file.name);

      const { data: res } = await uploadAdmin(form);

      this.form.headImgUrl = res.data
    },
    //获取单个数据
    async userIdAxios() {
      const res = await getUserId({ uid: this.userId });
      this.form = res.data.data;
    },
    //保存
    async sumbitClick() {
      const {data: res} = await editsSave(this.form);
      if(res.code == '10200'){
        this.$router.go(-1)
      }
      //console.log(res);
    },
  },
};
</script>

<style scoped lang='less'>
.information {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px 20px;
  font-family: "PingFang SC";

  .information_flex{
    display: flex;
    flex-direction: row;
    align-content: center;

    .information_left{
      margin-right: 50px;
    }
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 50%;
  }
  .avatar {
    width: 70px;
    border-radius: 50%;
    height: 70px;
    display: block;
  }

  /deep/ .el-form--inline .el-form-item {
    margin-right: 0 !important;
    width: 40%;
  }

  .information_title {
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    line-height: 2em;
    font-size: 22px;
    color: #151515;
  }
}
</style>